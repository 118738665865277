import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import "./assets/css/main.css"
import "bootstrap/dist/css/bootstrap.min.css"
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { initFacebookSdk } from "@/_services";
import { createWatcher }from 'next-vue-storage-watcher';

import { VueReCaptcha } from 'vue-recaptcha-v3'

// initAppleJS()

export const lsWatcher = createWatcher({
    prefix:"ovsu_"
})

// wait for facebook sdk to start app
initFacebookSdk().then(startApp);

function startApp() {
    const VueTelInputOptions = {
        mode: "international",
        onlyCountries: ["SG"],
        dropdownOptions: {
            disabled: true,
            showDialCodeInList: false
        },
        defaultCountry: "SG"
    }
    
    createApp(App).use(router).use(Vuex)
    .use(VueSweetalert2)
    .use(VueTelInput, VueTelInputOptions)
    .use(VueReCaptcha, { 
        siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount('#app')
}



import "bootstrap/dist/js/bootstrap.js"

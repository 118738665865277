<template>
  <BaseHeader />
  <router-view />
  <BaseFooter />
</template>

<script>
import BaseFooter from '@/components/common/BaseFooter'
import BaseHeader from '@/components/common/BaseHeader'

export default {
  name: 'App',
  components: {
    BaseHeader,
    BaseFooter
  },
  created() {
    // var scripts = [
    //   "https://code.jquery.com/jquery-3.5.1.slim.min.js",
    //   "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js",
    // ];
    // scripts.forEach(script => {
    //   let tag = document.createElement("script");
    //   tag.setAttribute("src", script);
    //   document.head.appendChild(tag);
    // });
  },
  methods: {
    async recaptcha(action) {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "signup".
      const token = await this.$recaptcha(action)

      // Do stuff with the received token.
      //   console.log(token)
      return token
    }
  }
}
</script>

<style>
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
</style>

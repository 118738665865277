<template>
  <nav class="navbar navbar-expand-md  main-nav" style="display:none">
    <div class="container">
      <!-- Brand -->
      <a class="navbar-brand" href="#"><img src="~@/assets/img/logo.png" class="img img-fluid"></a>

      <!-- Toggler/collapsibe Button -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"><img src="~@/assets/img/menu.png" class="img img-fluid"></span>
      </button>

      <!-- Navbar links -->
      <div class="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#service">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#tutorial">Tutorial</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#merchant">Merchant </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#faq">FAQ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: "BaseHeader"
  }
</script>

<style scoped>

</style>
